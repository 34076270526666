import Swiper from 'swiper';

export const heroCarousel = () => {
    document.querySelectorAll('.js-hero-carousel').forEach(carouselInstance => {
        // Turn the first image into a background image, to force a maximum height
        let image = carouselInstance.querySelector('img');

        if (image) {
            let imageSource = image.currentSrc;

            let focalPointYPercentage = 50;
            let focalPointXPercentage = 50;

            if ('focalpointYPercentage' in image.dataset) {
                focalPointYPercentage = image.dataset.focalpointYPercentage;
            }

            if ('focalpointXPercentage' in image.dataset) {
                focalPointXPercentage = image.dataset.focalpointXPercentage;
            }

            image.style.setProperty('display', 'none');

            let parent = image.closest('div');

            parent.style.setProperty('background-image', "url('" + imageSource + "')");
            parent.style.setProperty('background-size', 'cover');
            parent.style.setProperty('background-repeat', 'no-repeat');
            parent.style.setProperty('background-position', focalPointXPercentage + '% ' + focalPointYPercentage + '%');

            image.addEventListener('load', function (event) {
                imageSource = image.currentSrc;
                parent.style.setProperty('background-image', "url('"+imageSource+"')");
            });

            // Create faux button
            let carouselOpenButton = document.createElement('div');
            carouselOpenButton.classList.add('hero-carousel__open-button');
            carouselOpenButton.append("Bekijk alle foto's");
            carouselInstance.querySelector('.hero-carousel__trigger').append(carouselOpenButton);

            // Create an overlay
            let carouselOverlay = document.createElement('div');
            carouselOverlay.classList.add('hero-carousel__overlay');
            carouselInstance.append(carouselOverlay);

            // Create header
            let carouselHeader = document.createElement('div');
            carouselHeader.classList.add('hero-carousel__header');

            let closeButton = document.createElement('button');
            closeButton.textContent = 'Venster sluiten';
            closeButton.classList.add('hero-carousel__close-button');
            closeButton.addEventListener('click', function (event) {
                carouselOverlay.classList.remove('hero-carousel__overlay--active');
            }, false);

            carouselHeader.append(closeButton);
            carouselOverlay.append(carouselHeader);

            // Create image container
            let imageContainer = document.createElement('div');
            imageContainer.classList.add('swiper-wrapper', 'hero-carousel__image-container');

            // Add images to container
            carouselInstance.querySelectorAll('.hero-carousel__image').forEach(image => {
                let slide = document.createElement('div');
                slide.classList.add('swiper-slide', 'hero-carousel__slide');

                let clone = image.cloneNode();
                clone.className = '';
                clone.classList.add('hero-carousel__image');
                slide.append(clone);
                imageContainer.append(slide);
            });

            carouselOverlay.append(imageContainer);

            let swiper = new Swiper(carouselOverlay, {
                loop: true,
                slidesPerView: 'auto',
                spaceBetween: 20,
                centeredSlides: true,
            });

            // Create footer
            let carouselFooter = document.createElement('div');
            carouselFooter.classList.add('hero-carousel__footer');

            let buttonPrevious = document.createElement('button');
            buttonPrevious.classList.add('hero-carousel__button', 'hero-carousel__button--previous');
            buttonPrevious.textContent = 'Vorige foto';
            buttonPrevious.addEventListener('click', function (event) {
                swiper.slidePrev();
            }, false);
            carouselFooter.append(buttonPrevious);

            let buttonNext = document.createElement('button');
            buttonNext.classList.add('hero-carousel__button', 'hero-carousel__button--next');
            buttonNext.textContent = 'Volgende foto';
            buttonNext.addEventListener('click', function (event) {
                swiper.slideNext();
            }, false);
            carouselFooter.append(buttonNext);

            carouselOverlay.append(carouselFooter);

            // Add trigger
            carouselInstance.querySelector('.hero-carousel__trigger').addEventListener('click', function (event) {
                carouselOverlay.classList.add('hero-carousel__overlay--active');
            }, false);
        }
    });
}
