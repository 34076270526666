export const tabbedContent = () => {
    let containerClass = 'js-tabbed-content';
    let openClass = 'tabbed-content__tab--open';
    let activeControlClass = 'selection-button--active';
    let controlClass = 'js-tabbed-content-link';

    function showTab(id) {
        let currentTab = document.querySelector(id);

        // Remove open-class from all tabs but the current
        for (let tab of currentTab.parentNode.children) {
            tab.classList.remove(openClass);
        }
        currentTab.classList.add(openClass);

        // Set active tab-control
        let tabContainer = currentTab.closest('.' + containerClass);
        for (let tabControl of tabContainer.querySelectorAll('.' + controlClass)) {
            tabControl.classList.remove(activeControlClass);
            tabControl.ariaSelected = "false";
        }
        let currentControl  = tabContainer.querySelector("a[href='"+id+"']");
        currentControl.classList.add(activeControlClass);
        currentControl.ariaSelected = "true";
    }

    // Show active tab on click
    document.addEventListener('click', function (event) {
        let target;

        if (event.target.matches('a')) {
            target = event.target;
        }else {
            target = event.target.closest('a');
        }

        if (target && target.matches('.' + controlClass)) {
            event.preventDefault();
            // Make sure scroll position stays the same
            let x = window.scrollX;
            let y = window.scrollY;
            location.hash = target.hash;
            window.scroll({top: y, left: x});
            showTab(target.hash);
        }
    }, false);

    function openTabOnWindowHash() {
        if (window.location.hash && document.querySelector(window.location.hash) && document.querySelector(window.location.hash).classList.contains('tabbed-content__tab')) {
            // Check if hash is set, show relevant tab
            showTab(window.location.hash);
        } else {
            //If no hash is set, show first tab
            document.querySelectorAll('.' + containerClass).forEach(tabContainer => {
               const firstControl = tabContainer.querySelector('.' + controlClass);
               console.log(firstControl, firstControl.hash)
               showTab(firstControl.hash);
            });
        }
    }

    openTabOnWindowHash();

    window.readspeakercallback = function() {
        if (window.readspeakercalledback !== undefined) {
            return false;
        }
        window.readspeakercalledback = true;

        openTabOnWindowHash();
    }
};
