// Find forms and parse their params, if they contain required information for the datalayer proxy the submit and trigger a datalayer push
export const formDataLayer = () => {

    const items = ["formType", "formPosition"];

    async function proxy(e, form, params) {
        // prevent the default action so we won't submit pre-pushing
        e.preventDefault();


        const dataLayerObject = Object.assign(
            {},
            {
                'omniLayer': true,
                'event': 'formSubmission',
            },
            params
        );

        window.dataLayer = window.dataLayer || [];

        // Push the composed objet to the dataLayer
        await window.dataLayer.push(dataLayerObject);

        // only after the push is complete submit
        form.submit();
    }

    const init = () => {

        document.querySelectorAll("form").forEach( form => {

            // convert all dataLayer properties to a dict
            const params = Array.from(form.querySelectorAll("input[name=\"dataLayer\"]")).reduce( (res, input) => {
                if(items.includes(input?.dataset?.type))  {
                    res[input.dataset.type] = input?.dataset?.value || false;
                    return res;
                }
            }, {});

            // only intercept form submits when the form contains required info
            if(Object.keys(params)?.length > 0 && Object.entries(params).every(val => items.includes(val[0]) && val[1])) {
                form.onsubmit = e => proxy(e, form, params);
            }
        });
    }

    return {
        init,
    }

}
