
export const contactFormTrigger = () => {
    if (document.getElementById("contactForm")) {

        const contactSubject = document.getElementById("id_subject");
        const subjectOptions = contactSubject.querySelectorAll("option");
        const clientTip = document.getElementsByClassName("contact-tip-client");
        const professionalTip = document.getElementsByClassName("contact-tip-professional");

        const receiveUpdates = document.getElementById("id_receive_updates")
        const receiveUpdatesParent = receiveUpdates.parentElement;

        const subjects = document.getElementById("id_subjects");
        const subjectsParent = subjects.parentElement;

        const selectOptionHiddenClass = 'select-option--hidden';
        const hiddenFormRowClass = 'form-row--hidden';

        let currentProf = document.querySelector('input[name="professional"]:checked').value;

        // Hide the update and subjects if the selected professional is 'no'
        function HideUpdatesAndSubjects() {
            if (currentProf === 'no') {
                if (!receiveUpdatesParent.classList.contains(hiddenFormRowClass)) {
                    receiveUpdatesParent.classList.add(hiddenFormRowClass);
                }

                if (!subjectsParent.classList.contains(hiddenFormRowClass)) {
                    subjectsParent.classList.add(hiddenFormRowClass);
                }
            }
        }

        function toggleOptions() {
            subjectOptions.forEach(option => {

                if (currentProf === "no") {
                    if (option.dataset.type === "pro") {
                        option.classList.add(selectOptionHiddenClass);
                    } else {
                        option.classList.remove(selectOptionHiddenClass);
                    }
                } else {
                    if (option.dataset.type === "pro") {
                        option.classList.remove(selectOptionHiddenClass);
                    } else {
                        option.classList.add(selectOptionHiddenClass)
                    }
                }
            })
        }

        function toggleSubject() {
            if (subjectsParent.classList.contains(hiddenFormRowClass)) {
                subjectsParent.classList.remove(hiddenFormRowClass);

            } else {
                subjectsParent.classList.add(hiddenFormRowClass);
            }
        }

        function toggleUpdates() {
            if (receiveUpdatesParent.classList.contains(hiddenFormRowClass)) {
                receiveUpdatesParent.classList.remove(hiddenFormRowClass);

            } else {
                receiveUpdatesParent.classList.add(hiddenFormRowClass);
            }
        }

        function toggleTips() {
            if (clientTip[0].classList.contains('contact-tip--client--hidden')) {
                professionalTip[0].classList.add("contact-tip-professional--hidden");
                clientTip[0].classList.remove("contact-tip--client--hidden");
            } else {
                professionalTip[0].classList.remove("contact-tip-professional--hidden");
                clientTip[0].classList.add("contact-tip--client--hidden");
            }
        }

        function resetUpdates() {
            receiveUpdates.checked = false;
        }

        function resetSubjects() {
            subjects.querySelectorAll('input').forEach(subject => {
                subject.checked = false;
            })
        }

        document.querySelectorAll('input[name="professional"]').forEach(obj => {
            obj.addEventListener('change', (e) => {
                let value = document.querySelector("input[name=" + e.target.name + "]:checked").value;

                if (value !== currentProf) {
                    currentProf = value;
                    toggleOptions();
                    toggleTips();
                    toggleUpdates();
                    contactSubject.selectedIndex = null;
                }
                if (value === 'no') {
                    resetUpdates();
                    resetSubjects();
                    HideUpdatesAndSubjects();
                }

            });
        });

        receiveUpdates.addEventListener("change", (e) => {
                toggleSubject();
            }
        )

        toggleOptions();
        HideUpdatesAndSubjects();
    }
}
