
export const webinarFormTrigger = () => {
    if (document.getElementById("webinarInschrijving")) {

        const selector = document.getElementById("id_individual");
        let selectorValue = null;

        const inputs = document.querySelectorAll('input, textarea, select')
        const recaptchaErrorRegions = document.querySelectorAll('.recaptcha-errors');

        const functionParent = document.getElementById("id_function").parentElement;
        const organisationParent = document.getElementById("id_organisation").parentElement;
        const locationParent = document.getElementById("id_location").parentElement;
        const cityParent = document.getElementById("id_city").parentElement;
        const receiveUpdatesInput = document.getElementById("id_receive_updates");
        const receiveUpdatesParent = document.getElementById("id_receive_updates").parentElement;
        const subjectsParent = document.getElementById("id_subjects").parentElement;
        const otherParent = document.getElementById("id_individual_other").parentElement;

        const regularOptions = ['professional', 'other']
        const regularQuestions = [functionParent, organisationParent, locationParent, receiveUpdatesParent, subjectsParent]

        const nahOptions = ['nah-patient', 'nah-relative', 'student']
        const nahQuestions = [cityParent]

        const hiddenFormRowClass = 'form-row--hidden';

        // clear recaptcha stuff
        const clearErrors = () => {
            if (window.grecaptcha) {
                window.grecaptcha.reset();
                Array.from(recaptchaErrorRegions).forEach((region) => {
                    region.innerHTML = null
                })
            }
        }

        // update the selector initially
        onSelectorChanged()

        // add listeners
        receiveUpdatesParent.addEventListener("change", subjectToggle.bind(this))
        selector.addEventListener("change", onSelectorChanged.bind(this));
        Array.from(inputs).forEach((el) => {
            el.addEventListener('change', clearErrors.bind(this))
            el.addEventListener('input', clearErrors.bind(this))
        })

        function onSelectorChanged() {
            if (selector.value !== selectorValue) {

                toggleRegularQuestions();
                toggleNahQuestions();
                toggleOther();
                subjectToggle();

                selectorValue = selector.value;
            }
        }

        // toggle the updates subjects list (only when 'receive updates' is visible & checked
        function subjectToggle() {

            const show = (regularOptions.includes(selector.value))
            const isChecked = !!document.querySelector("input[name=" + receiveUpdatesInput.name + "]:checked");

            if (show && isChecked) {
                subjectsParent.classList.remove(hiddenFormRowClass)
            } else {
                subjectsParent.classList.add(hiddenFormRowClass)
            }
        }

        // toggle all fields linked to the regular fields group.
        function toggleRegularQuestions() {

            const show = (regularOptions.includes(selector.value))

            regularQuestions.forEach(question => {
                if (show) {
                    question.classList.remove(hiddenFormRowClass);
                } else {
                    question.classList.add(hiddenFormRowClass);
                }
            })
        }

        // toggle all fields linked to the 'limited' fields group.
        function toggleNahQuestions() {

            const show = (nahOptions.includes(selector.value))

            nahQuestions.forEach(question => {
                if (show) {
                    question.classList.remove(hiddenFormRowClass);
                } else {
                    question.classList.add(hiddenFormRowClass);
                }
            })
        }

        // if 'other' is selected, show an extra field to define your profession
        function toggleOther() {

            const show = (selector.value === 'other')

            if (show) {
                otherParent.classList.remove(hiddenFormRowClass);
            } else {
                otherParent.classList.add(hiddenFormRowClass);
            }
        }
    }
}
