import Vivus from 'vivus';

export const heroAnimation = () => {

    function getCookie(name) {
        let value = `; ${document.cookie}`;
        let parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    function setCookie(cName, cValue, expDays) {
        let date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    }

    const conf = {
        start: 'inViewport',
        type: 'oneByOne',
        duration: 500,
        animTimingFunction: Vivus.EASE
    }

    let illustration = document.querySelector('.hero-animation');
    let animationShown = getCookie('animation-shown');
    if (illustration !== null && animationShown === undefined) {
        let animation = new Vivus(illustration, conf);
        setCookie('animation-shown', 'true', 30);
    }
}
