export const hamburger = () => {

    function ToggleMenu(event) {
        let hamburger = document.querySelector('.hamburger-toggle-label');
        let hamburgerLabel = hamburger.getAttribute('aria-label');

        if (hamburgerLabel && hamburgerLabel === 'Open menu') {
            hamburger.setAttribute('aria-label', 'Close menu')
        } else {
            hamburger.setAttribute('aria-label', 'Open menu')
        }
    }

    document.addEventListener('click', function (event) {
        if (event.target.matches('.js-toggle-hamburger')) {
            ToggleMenu(event);
        }
    }, false);
};
