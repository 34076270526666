export const darkmode = () => {
    const html = document.querySelector('html');

    function getCookie(name) {
        let value = `; ${document.cookie}`;
        let parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    function setCookie(cName, cValue, expDays) {
        let date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    }

    function setDarkMode(dark) {
        html.setAttribute('data-theme', dark ? 'dark' : 'light');
        setCookie('theme', dark ? 'dark' : 'light', 30);
    }

    // Toggles between aria-checked="true" and aria-checked="false" in addition to role="switch"
    function toggleDarkModeAttribute(darkModeSwitch) {
        if (darkModeSwitch) {
            if(darkModeToggle.checked) {
                darkModeToggle.setAttribute('aria-checked', 'true');
            }
            else {
                darkModeToggle.setAttribute('aria-checked', 'false');
            }
        }
    }

// Select prefers-color-scheme media query for dark mode
    let darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

// Create toggle element to set dark mode
    const darkModeToggle = document.createElement('input');
    darkModeToggle.setAttribute('type', 'checkbox');
    darkModeToggle.setAttribute('id', 'dark-mode-toggle');
    darkModeToggle.classList.add('dark-mode-toggle__input');
    darkModeToggle.checked = darkModeMediaQuery.matches;
    toggleDarkModeAttribute(darkModeToggle);

    darkModeToggle.addEventListener(
        'change',
        e => {
            setDarkMode(e.currentTarget.checked);
            toggleDarkModeAttribute(darkModeToggle);
        }
    )

// Create label for dark mode toggle
    const darkModeToggleLabel = document.createElement('label');
    darkModeToggleLabel.setAttribute('for', 'dark-mode-toggle');
    darkModeToggleLabel.classList.add('dark-mode-toggle__label')
    darkModeToggleLabel.innerText = 'Licht/donker';
    darkModeToggleLabel.setAttribute('role', 'switch');

    const darkModeToggleIcon = document.createElement('div');
    darkModeToggleIcon.classList.add('dark-mode-toggle__icon');
    darkModeToggleLabel.prepend(darkModeToggleIcon);

    const navigationItem = document.createElement('li');
    navigationItem.classList.add('navigation-secondary__item');
    const darkModeToggleContainer = document.createElement('div');
    darkModeToggleContainer.classList.add('dark-mode-toggle');

    darkModeToggleContainer.append(darkModeToggle);
    darkModeToggleContainer.append(darkModeToggleLabel);
    navigationItem.appendChild(darkModeToggleContainer);
    document.querySelector('.navigation-secondary').append(navigationItem);

// Check if theme cookie is set and use it to set dark or light mode
    let theme = getCookie('theme');
    if (theme !== undefined) {
        darkModeToggle.checked = (theme === 'dark');
        setDarkMode(theme === 'dark');
    }

// Watch if user OS switches to light or dark mode
    darkModeMediaQuery.addEventListener(
        'change',
        e => {
            darkModeToggle.checked = e.matches;
            setDarkMode(e.matches);
            toggleDarkModeAttribute(darkModeToggle);
        }
    );
}
