import { throttle } from '../utils/throttle-debounce'

export default class EmployeeItem {

    constructor (el) {

        this._btn = el.querySelector('.employee-item__biography-btn')
        this._bio = el.querySelector('.employee-item__biography')
        this._bioContent = el.querySelector('.employee-item__biography > p')
        this._isOpen = false

        this._initialize()
    }

    _initialize () {
        if (this._btn && this._bio) {

            this._label = this._btn.innerHTML;
            this._openLabel = this._btn.getAttribute('data-open-label')
            this._addEventListeners()
            this._onResize()
        }
    }

    _addEventListeners () {
        this._btn.addEventListener('click', this._toggleBiography.bind(this))
        window.addEventListener('resize', throttle(this._onResize.bind(this), 1000 / 60))
    }



    _toggleBiography (e) {
        this._isOpen = !this._isOpen
        this._btn.classList.toggle('employee-item__biography-btn--open')
        this._bio.classList.toggle('employee-item__biography--open')
        this._onResize()
        this._updateLabel()
    }

    _onResize (e) {
        if (this._isOpen) {
            const rect = this._bioContent.getBoundingClientRect()
            this._bio.style.height = rect.height + 'px'
        } else {
            this._bio.removeAttribute('style')
        }
    }

    _updateLabel () {
        this._btn.innerHTML = (this._isOpen) ? this._openLabel : this._label
    }
}
