import './stylesheets/screen.scss';

import initializeComponents from 'scripts/libs/component-loader';

const html = document.querySelector('html');
html.classList.add('js-enabled');

import {darkmode} from "./scripts/utils/darkmode.jsx";
darkmode();

import {faq} from "./scripts/utils/faq.jsx";
faq();

import {hamburger} from "./scripts/utils/hamburger.jsx";
hamburger();

import {tabbedContent} from "./scripts/utils/tabbed-content.jsx";
tabbedContent();

import {contactOverlay} from "./scripts/utils/contact-overlay.jsx";
contactOverlay();

import {heroCarousel} from "./scripts/utils/hero-carousel.jsx";
heroCarousel();

import {heroAnimation} from "./scripts/utils/hero-animation.jsx";
heroAnimation();

import {formDataLayer} from "./scripts/utils/formDataLayer.jsx";
formDataLayer().init();

import {contactFormTrigger} from "./scripts/utils/contactForm.jsx";
contactFormTrigger();

import {webinarFormTrigger} from "./scripts/utils/webinarForm.jsx";
webinarFormTrigger();

document.addEventListener('DOMContentLoaded', (event) => {
  initializeComponents(event.target);
})
