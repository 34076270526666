import Cookies from 'js-cookie';
import { EventEmitter } from 'events';

const COOKIEWALL_COOKIE = 'cookies';
const COOKIEWALL_ACCEPTED = 'ok';

export default class CookieWall extends EventEmitter {
  constructor() {
    super();

    if (!this.isAccepted() && document.referrer) {
      this.checkNavigate();
    }
  }

  static getInstance() {
    if (CookieWall.instance === undefined) {
      CookieWall.instance = new CookieWall();
    }
    return CookieWall.instance;
  }

  checkNavigate() {
    const referrer = document.createElement('A');
    referrer.href = document.referrer;

    if (referrer.hostname === document.location.hostname) {
      this.accept();
    }
  }

  accept() {
    Cookies.set(COOKIEWALL_COOKIE, COOKIEWALL_ACCEPTED);
    this.emit('cookies-accepted');
  }

  // eslint-disable-next-line class-methods-use-this
  isAccepted() {
    return Cookies.get(COOKIEWALL_COOKIE) === COOKIEWALL_ACCEPTED;
  }
}
