import CookieWall from './cookie-wall';

export default class CookieDeferred {
  constructor(el) {
    this.el = el;
    this.deferredMessage = el.querySelector('.cookie-message__deferred');
    this.cookieWall = CookieWall.getInstance();

    this.cookieWall.on('cookies-accepted', () => {
      this.removeDeferredMessage();
      this.initCookies(el);
    });

    if (this.deferredMessage !== undefined) {
      this.deferredMessage.addEventListener('click', () => {
        this.acceptCookies();
      });
    }

    if (this.isCookieAccepted()) {
      this.removeDeferredMessage();
      this.initCookies(el);
    } else {
      this.showDeferredMessage();
      this.initNoCookies(el);
    }
  }

  removeDeferredMessage() {
    if (this.deferredMessage !== undefined) {
      this.deferredMessage.parentNode.removeChild(this.deferredMessage);
    }
  }

  showDeferredMessage() {
    if (this.deferredMessage !== undefined) {
      this.deferredMessage.classList.add('cookie-message__deferred--visible');
    }
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  initCookies(el) {
    // To be subclassed
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  initNoCookies(el) {
    // To be subclassed
  }

  isCookieAccepted() {
    return this.cookieWall.isAccepted();
  }

  acceptCookies() {
    this.cookieWall.accept();
  }
}
