export const contactOverlay = () => {
    const overlayElement = document.querySelector('.js-contact-overlay');
    const overlayElementLink = overlayElement.getElementsByTagName("a")[0];
    const activeClass = 'contact-overlay--active';
    const html = document.querySelector('html');
    const htmlClass = 'overlay-active';

    const closeButton = document.createElement('button');
    closeButton.textContent = 'Venster sluiten';
    closeButton.ariaLabel = 'Help venster sluiten';
    closeButton.classList.add('contact-overlay__close-button');

    closeButton.addEventListener('click', function (event) {
        overlayElement.classList.remove(activeClass);
        html.classList.remove(htmlClass);
        document.activeElement.blur();
    }, false);

    closeButton.addEventListener('blur', function (event) {
        overlayElementLink.focus();
    })

    overlayElement.append(closeButton);

    let overlayTriggers = document.querySelectorAll('.js-contact-overlay-trigger');

    overlayTriggers.forEach(function (trigger) {
        trigger.addEventListener('click', function (event) {
            overlayElement.classList.add(activeClass);
            html.classList.add(htmlClass);

            // close the main menu
            document.querySelector('.js-toggle-hamburger').checked = false

            overlayElementLink.focus();
        });
    });
}
