export const faq = () => {

    function expandFaq(question, openDetail) {
        let faqItem = question.closest('.faq__item');
        let openClass = 'faq__item--open';

        if (faqItem.classList.contains(openClass)) {
            faqItem.classList.remove(openClass);
            question.ariaExpanded = 'false';
        } else {
            faqItem.classList.add(openClass);
            question.ariaExpanded = 'true';
        }

        // We only want to trigger this once with an anchor
        if (openDetail) {
            faqItem.open = true;
        }
    }

    document.addEventListener('click', function (event) {
        if (event.target.matches('.js-faq-expand')) {
            expandFaq(event.target);
        }
    }, false);

    if (window.location.hash) {
        let question = document.getElementById(window.location.hash.substring(1));

        if (question && question.classList.contains('faq__question')) {
            expandFaq(question, true);
        }
    }
};
